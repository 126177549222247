<template>
    <div id="bimbingan" class="mcontainer">
        <div class="md:flex justify-between relative md:mb-4 mb-3">
            <h2 class="text-lg font-semibold color-dark">Daftar Mahasiswa Bimbingan Skripsi</h2>
            <button @click="inviteMahasiswaShow" class="flex d-inline items-center justify-center px-3 py-2 rounded-md bg-blue-600 text-white md:absolute right-0">
                <span class=""> Select Mahasiswa </span>
            </button>
        </div>

        <div class="header_search search-news mt-4">
            <i class="uil-search-alt"></i>
            <input type="text" placeholder="Cari Mahasiswa" autocomplete="off" class="form-control rounded-md" style="min-width: 100%;">
        </div>

        <div class="horizontal-menu my-3">
            <router-link :to="{ name: 'MahasiswaBimbingan' }">Mahasiswa Bimbingan</router-link>
            <router-link :to="{ name: 'JadwalBimbingan' }">Pengajuan Jadwal Bimbingan</router-link>
            <router-link :to="{ name: 'MahasiswaSelesaiBimbingan' }">Mahasiswa Selesai Bimbingan</router-link>
        </div>

        <transition name="slide-fade" mode="out-in">
            <router-view class="mt-4" />
        </transition>

        <modal name="invite-mahasiswa" :height="600" :width="400">
            <div class="p-3">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="text-black font-semibold dark:text-gray-100">
                        Pilih Mahasiswa
                    </h5>
                    <a href="" @click.prevent="inviteMahasiswaClose">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="currentColor"/>
                        </svg>
                    </a>
                </div>
                <div class="position-relative invite-search mt-4">
                    <span class="search-icon">
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77666 16 8C16 6.41775 15.5308 4.87103 14.6518 3.55544C13.7727 2.23985 12.5233 1.21447 11.0615 0.608967C9.59966 0.00346625 7.99113 -0.15496 6.43928 0.153721C4.88743 0.462403 3.46197 1.22433 2.34315 2.34315C1.22433 3.46197 0.462403 4.88743 0.153721 6.43928C-0.15496 7.99113 0.00346625 9.59966 0.608967 11.0615C1.21447 12.5233 2.23985 13.7727 3.55544 14.6518C4.87103 15.5308 6.41775 16 8 16C9.77666 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.383 17.8037 16.4936 17.8781 16.6154 17.9289C16.7373 17.9797 16.868 18.0058 17 18.0058C17.132 18.0058 17.2627 17.9797 17.3846 17.9289C17.5064 17.8781 17.617 17.8037 17.71 17.71C17.8037 17.617 17.8781 17.5064 17.9289 17.3846C17.9797 17.2627 18.0058 17.132 18.0058 17C18.0058 16.868 17.9797 16.7373 17.9289 16.6154C17.8781 16.4936 17.8037 16.383 17.71 16.29ZM2 8C2 6.81332 2.3519 5.65328 3.01119 4.66658C3.67047 3.67989 4.60755 2.91085 5.7039 2.45673C6.80026 2.0026 8.00666 1.88378 9.17055 2.11529C10.3344 2.3468 11.4035 2.91825 12.2426 3.75736C13.0818 4.59648 13.6532 5.66558 13.8847 6.82946C14.1162 7.99335 13.9974 9.19975 13.5433 10.2961C13.0892 11.3925 12.3201 12.3295 11.3334 12.9888C10.3467 13.6481 9.18669 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8Z" fill="currentColor"/>
                        </svg>
                    </span>
                    <input type="text" placeholder="Cari Mahasiswa" v-model="inviteMahasiswa.search" @keyup.enter="inviteMahasiswaGetUsers">
                    <button class="btn btn-primary search-button" @click="inviteMahasiswaGetUsers">
                        Cari
                    </button>
                </div>
                <hr class="my-4">
                <div class="invite-mahasiswa">
                    <loading :loading="inviteMahasiswa.usersLoading" spinner-class="justify-content-center">
                        <smooth-scrollbar v-if="inviteMahasiswa.users.length > 0" style="height: 370px;">
                            <div v-for="user in inviteMahasiswa.users" :key="user.id" class="d-flex justify-content-between align-items-center pr-2">
                                <div class="d-flex my-2 align-items-center">
                                    <div class="mr-3">
                                        <img :src="user.images !== '' && user.images !== null ? user.images : require('@/assets/images/avatars/avatar-1.jpg')" class="w-16 h-16 rounded-full">
                                    </div>
                                    <div>
                                        <p class="m-0 text-black font-semibold dark:text-gray-100">
                                            {{ user.fullname }}
                                        </p>
                                        <p class="m-0">
                                            {{ user.email }}
                                        </p>
                                    </div>
                                </div>
                                <b-form-checkbox
                                    :value="true"
                                    :unchecked-value="false"
                                    @change="(value) => inviteMahasiswaChecklistChange(user.id, value)"
                                />
                            </div>
                        </smooth-scrollbar>
                        <div v-else style="height: 370px" class="pt-5 px-3">
                            <empty text="Tidak ada mahasiswa untuk melakukan skripsi" />
                        </div>
                    </loading>
                </div>
                <div>
                    <button class="btn btn-primary search-button d-block w-full mt-3" @click="inviteMahasiswaAction">
                        <span v-if="inviteMahasiswa.loading"><b-spinner small label="Loading..." /></span>
                        Pilih Mahasiswa
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    import axios from 'axios'
    import Empty from '@/components/Empty.vue'

    export default {
        name: 'BimbinganIndex',
        components: {
            Empty
        },
        data () {
            return {
                inviteMahasiswa: {
                    loading: false,
                    search: '',
                    users: [],
                    usersLoading: false,
                    checkedUsers: []
                }
            }
        },
        methods: {
            inviteMahasiswaShow () {
                this.inviteMahasiswaGetUsers()
                this.$modal.show('invite-mahasiswa')
            },
            inviteMahasiswaGetUsers () {
                this.inviteMahasiswa.usersLoading = true
                this.inviteMahasiswa.users = []
                axios.get('skripsi/mahasiswa?search=' + this.inviteMahasiswa.search)
                    .then(res => {
                        this.inviteMahasiswa.users = res.data.result
                    })
                    .finally(() => {
                        this.inviteMahasiswa.usersLoading = false
                    })
            },
            inviteMahasiswaClose () {
                this.$modal.hide('invite-mahasiswa')
                this.inviteMahasiswa.search = ''
                this.inviteMahasiswa.users = []
                this.inviteMahasiswa.checkedUsers = []
            },
            inviteMahasiswaChecklistChange (userId, value) {
                if (value) {
                    this.inviteMahasiswa.checkedUsers.push(userId)
                } else {
                    const findIndex = this.inviteMahasiswa.checkedUsers.findIndex(i => (i === userId))
                    if (findIndex > -1) {
                        this.inviteMahasiswa.checkedUsers.splice(findIndex, 1)
                    }
                }
            },
            inviteMahasiswaAction () {
                this.inviteMahasiswa.loading = true
                axios.post('skripsi/inviteMahasiswa', {
                    members: this.inviteMahasiswa.checkedUsers
                })
                    .then(res => {
                        this.$swal(
                            'Success!',
                            'Mahasiswa telah ditambahkan dalam skripsi.',
                            'success'
                        ).then(res => {
                            this.$router.go(this.$router.currentRoute)
                        })
                    })
                    .finally(() => {
                        this.inviteMahasiswa.loading = false
                    })
            }
        }
    }
</script>
